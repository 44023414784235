<div class="notification-box" (click)="toggleNotificationMobile()">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="50"
    viewBox="0 0 50 50">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_18073" data-name="Rectangle 18073" width="20" height="20" rx="5"
          transform="translate(1606 25)" fill="#fff" />
      </clipPath>
    </defs>
    <g id="notif_icon" data-name="notif icon" transform="translate(-1666 -21)">
      <rect id="Rectangle_18072" data-name="Rectangle 18072" width="50" height="50" rx="10"
        transform="translate(1666 21)" fill="#047bbc" />
      <g id="Mask_Group_40772" data-name="Mask Group 40772" transform="translate(75 11)" clip-path="url(#clip-path)">
        <g id="_28" data-name="28" transform="translate(1607.012 25)">
          <path id="Path_35078" data-name="Path 35078"
            d="M21.266,16.9,20.045,15.14a1.428,1.428,0,0,1-.257-.814V9.284a7.142,7.142,0,1,0-14.283,0v5.042a1.428,1.428,0,0,1-.257.814L4.026,16.9a1.428,1.428,0,0,0,1.057,2.385H9.146a3.571,3.571,0,0,0,7,0h4.064A1.428,1.428,0,0,0,21.266,16.9Zm-8.62,3.814a2.142,2.142,0,0,1-2.014-1.428H14.66a2.142,2.142,0,0,1-2.014,1.428ZM5.083,17.854a.578.578,0,0,0,.086-.1l1.25-1.8a2.857,2.857,0,0,0,.514-1.628V9.284a5.713,5.713,0,1,1,11.427,0v5.042a2.857,2.857,0,0,0,.514,1.628l1.25,1.8a.579.579,0,0,0,.086.1Z"
            transform="translate(-3.658 -2.142)" fill="#fff" />
        </g>
      </g>
    </g>
  </svg>
  <span class="badge badge-secondary" *ngIf="notificationBadgeCount !== 0"
    >{{notificationBadgeCount>9?'9+':notificationBadgeCount}}</span>
</div>
<ng-container *ngIf="isTouchDevice ? openClosedSidebarNotification : !openClosedSidebarNotification">
  <div class="onhover-show-div notification-dropdown d-flex flex-column "
    [ngStyle]="{'height': notificationBadgeCount <= 4 ? 'auto' :'25.2rem'}">
    <ul *ngIf="checkAsmarkStatus()" class="flex-grow-0 flex-shrik-1">
      <li class="border-left-blue border-left-6"
        *ngFor="let item of notificationList | filter: 'isRead': false | slice:0:4">
        <p><span class="col">{{item.title | translate}}</span><span
            class="text-decoration-underline col three-dots text-end cursor-pointer"
            (click)="markAsread(item)">{{'HEADER.NOTIFICATIONS.MARKASREAD'|translate}}</span></p>
        <p><span class="col-7">{{item.body}}</span> <span
            class="text-decoration-underline col-5 text-end align-self-end">{{item.timeDuration}}</span></p>
      </li>
      <!-- <li class="b-l-transparent border-left-6"><p class=""><span class="notification-footer text-decoration-underline cursor-pointer" (click)="markAllAsread(notificationStatus.read , notificationStaticData.notifications)">{{'HEADER.NOTIFICATIONS.MARKALLASREAD'|translate}}</span><span class="notification-footer text-decoration-underline cursor-pointer" (click)="openEnd(notificationSidebarComponent?.content)">{{'HEADER.NOTIFICATIONS.VIEW.ALL'|translate}}</span></p></li> -->
    </ul>
    <div *ngIf="checkAsmarkStatus()" class="notification-footer-contain">
      <p class="d-flex justify-content-between px-2 m-0"><span [ngClass]="{'invisible': !checkAsmarkStatus()}"
          class="notification-footer text-decoration-underline cursor-pointer"
          (click)="markAsread(null)">{{'HEADER.NOTIFICATIONS.MARKALLASREAD'|translate}}</span><span
          class="notification-footer text-decoration-underline cursor-pointer"
          (click)="openEnd(notificationSidebarComponent?.content)">{{'HEADER.NOTIFICATIONS.VIEW.ALL'|translate}}</span>
      </p>
    </div>

    <div *ngIf="!checkAsmarkStatus()" class="d-flex flex-column align-items-center py-4">
      <div class="color-primary-grey font-Medium text-nowrap f-14 mb-2">{{'HEADER.NOTIFICATION.NO_NEW_NOTIFICATIONS' |
        translate}}</div>
      <p class="d-flex justify-content-between mb-0 mt-2"><span
          class="font-semi-bold f-12 color-primary text-decoration-underline cursor-pointer"
          (click)="openEnd(notificationSidebarComponent?.content)">{{'HEADER.NOTIFICATIONS.VIEW_NOTIFICATIONS'|translate}}</span>
      </p>
    </div>
  </div>
</ng-container>
<app-notification-sidebar (onScroll)="onScroll($event)" (deleteNotification)="deleteNotification($event)" (markAsRead)="markAsread($event)" (clickOnNotificationTab)="getNotificationOnTabClick($event)"  [notificationsData]="notificationList" *ngIf="openClosedSidebarNotification"
  #notificationSidebarComponent></app-notification-sidebar>