<!-- (mouseleave)="mouseLeavefromSelect($event)" -->
<div class="custom_dropdown_container {{dynamic_class}}"appClickOutside (clickOutside)="onOutsideClick($event)">
  <div class="custom-dropdown" [class.open]="dropdownOpen" [ngClass]="{'pe-none opacity-50': isDisabled}" >
    <div class="selected-option d-flex align-items-center" [ngClass]="{'select-focused': selectedOption?.value}"
      (click)="!isDisabled?toggleDropdown():''">

      <div class="d-flex align-items-center w-100 gap-1">
        <ng-container>
          <input [readonly]="true" [required]="isRequired" autocomplete="disabled"
            [placeholder]="placeHolderText != undefined ? placeHolderText:'REPORTS.REPORTING.FILTERPOPUP.LANGUAGE.SELECT' | translate"
            [id]="id" [name]="id" class="px-0 text-truncate cursor-pointer border-0 bg-transparent dropdown-input w-100 d-flex"
            [(ngModel)]="selectedOption!= undefined? (selectedOption?.value != selectedOption?.languageVariant[0]?.value)? selectedOption.value +' - '+ variantselectedOption.value : selectedOption.value: selectedOption"
            #content placement="bottom"
            [ngbTooltip]="(isTextOverflowing ? (selectedOption?.value != selectedOption?.languageVariant[0]?.value)? selectedOption?.value +' - '+ variantselectedOption?.value : selectedOption?.value : null) | translate"
            (mouseenter)="checkTextOverflow((selectedOption?.value != selectedOption?.languageVariant[0]?.value)? selectedOption?.value +' - '+ variantselectedOption?.value : selectedOption?.value)">
        </ng-container>

        <div class="d-flex">
          <i id="ICON_-_drop-2" class="fa-solid fa-caret-down cursor-pointer f-16"  
          [ngClass]="{'selected': selectedOption?.value, 'default': !selectedOption?.value,'fa-caret-up':dropdownOpen}"
              ></i>
        </div>
        <!-- reset svg -->
       <div>
        <svg class="reset_icon" *ngIf="selectedOption" (click)="clearSelection($event);" id="Icon:_reset"
        data-name="Icon: reset" xmlns="http://www.w3.org/2000/svg" width="15" height="15" style="margin-top:6px;" viewBox="0 0 18 21">
        <g id="Group_41566" data-name="Group 41566">
          <path id="Path_35107" data-name="Path 35107"
            d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z"
            transform="translate(-4 -2)" [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
        </g>
        <g id="Group_41567" data-name="Group 41567" transform="translate(0.231 0.95)">
          <path id="Path_35108" data-name="Path 35108"
            d="M12.507,22A9.3,9.3,0,0,1,9.1,21.367a10.057,10.057,0,0,1-4.687-3.694,1.032,1.032,0,0,1,.32-1.478,1.053,1.053,0,0,1,1.491.317,7.438,7.438,0,0,0,3.622,2.85,7.873,7.873,0,0,0,4.687.211,7.428,7.428,0,0,0,3.835-2.639,7.972,7.972,0,0,0,1.6-4.327A7.31,7.31,0,0,0,18.58,8.174,8.015,8.015,0,0,0,14.851,5.43a7.135,7.135,0,0,0-4.687,0A8.015,8.015,0,0,0,6.435,8.174a1.053,1.053,0,0,1-1.491.317.951.951,0,0,1-.213-1.478A9.495,9.495,0,0,1,9.524,3.425a9.6,9.6,0,0,1,5.966,0,9.495,9.495,0,0,1,4.794,3.588,9.017,9.017,0,0,1,1.7,5.7,9.017,9.017,0,0,1-2.024,5.594,10.319,10.319,0,0,1-5.007,3.377,8.615,8.615,0,0,1-2.45.317Z"
            transform="translate(-4.231 -2.95)" [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
        </g>
      </svg>
       </div>
        <!-- reset svg -->
      </div>

    </div>
    <div *ngIf="dropdownOpen" class="dropdown-options pe-0 position-absolute"
      [ngStyle]="{ 'height': getDropdownHeight() }">
      <ul Class="first_list px-1 f-12 ">
        <li
          [ngClass]="{'disabled':(group?.value == group?.languageVariant[0]?.value)?hasDisabled(group, undefined):null}"
          *ngFor="let group of languageDropDownDataArray;let i = index;" (click)="group.isOpen = !group.isOpen"
          (click)="group?.value == group?.languageVariant[0]?.value ?selectOption(group,undefined):null">
          <span class="group-label color-primary-grey text-truncate">{{ selectedLanguage === 'en-US' ? group.value : group.spanishValue }}</span>
          <ul *ngIf="group.isOpen && group?.value != group?.languageVariant[0]?.value" class="ps-4 f-12 inside_list">
            <li [ngClass]="{'disabled':hasDisabled(group, option)}" *ngFor="let option of group?.languageVariant"
              (click)="selectOption(group,option)">
              <span class="pe-2">-</span>{{ selectedLanguage === 'en-US' ? option.value : option.spanishValue }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="addLanguageIcon"
    class="add_button position-absolute top-0 d-flex align-items-center justify-content-center cursor-pointer"
    (click)="addLangugage(selectedOption,variantselectedOption)">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <path id="Path_35092" data-name="Path 35092"
        d="M150.772,141.488h-5v-5a1.25,1.25,0,1,0-2.5,0v5h-5a1.25,1.25,0,0,0,0,2.5h5v5a1.25,1.25,0,0,0,2.5,0v-5h5a1.25,1.25,0,1,0,0-2.5Z"
        transform="translate(-137.022 -135.238)" fill="#fff" />
    </svg>
  </div>
</div>