
export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyDJsdrpWrsc_QEIO2EH1owtknOp57KdJrU",
        authDomain: "cielo---stage.firebaseapp.com",
        projectId: "cielo---stage",
        storageBucket: "cielo---stage.appspot.com",
        messagingSenderId: "233606071380",
        appId: "1:233606071380:web:a0c1f99eb63df96415d9c1",
        measurementId: "G-1ERRXX2BTS",
        vapidKey: "BAYk3zP7T3Pm3zmOmbGKGm7mcqy2F-uSNpD_D31lJcnBZun6cYTn5lM5PY0lPpF-LyfrJc3bn4viHZNZPgE0934"
    },
    quickbooks: {
        clientId: 'BBw6aZ0fwaUVjb31PttG3J0dkI47BcjLMPXNU5SfUB0nbu7EIh',
        redirectUri: 'https://devcieloadmin.12skiestech.com/dashboard',
        authUri: 'https://appcenter.intuit.com/connect/oauth2',
        tokenUri: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer'
      }
};

export const baseUrl = "https://devcieloapi.12skiestech.com/";

