import { DatePipe, TranslationWidth } from '@angular/common';
import { Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { LanguageServiceService } from '../../components/header/elements/languages/language-service.service';
import { CommonService } from '../../services/common.service';
import { StorageService } from '../../services/storage.service';
const I18N_VALUES = {
  'en-US': {
    weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  'es-ES': {
    weekdays: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
    months: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
  },
  // other languages you would support
};

  @Injectable()
  export class I18n {
	language = 'en-US';
  }
  @Injectable()
  export class CustomDatepickerI18n extends NgbDatepickerI18n {
	selectedLanguage: any;
	constructor(private _i18n: I18n,public languageServiceService:LanguageServiceService) {
	  super();
	  this.selectedLanguage = this.languageServiceService.selectedLanguage.getValue();
	}
	getWeekdayShortName(weekday: number): string {
	  if(this.selectedLanguage?.code == 'es'){
		this._i18n.language = 'es-ES';
	  }
	  return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	getMonthShortName(month: number): string {
	  if(this.selectedLanguage?.code == 'es'){
	  	this._i18n.language = 'es-ES';
	  }
	  return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
	  return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
	  return `${date.day}-${date.month}-${date.year}`;
	}
	getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
		if(this.selectedLanguage?.code == 'es'){
			this._i18n.language = 'es-ES';
		}
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
  }

export class SelectedDates {
	startDate: NgbDate;
}

@Component({
  selector: 'app-date-single',
  templateUrl: './date-single.component.html',
  styleUrls: ['./date-single.component.scss'],
  viewProviders: [ I18n,{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, { provide: ControlContainer, useExisting: NgForm },DatePipe],
  encapsulation: ViewEncapsulation.None,
})
export class DateSingleComponent extends CommonService implements OnChanges,OnInit {
  @Input() item = ''; // decorate the property with @Input()
  @Input() datepickername;
  @Input() inputDate: string = "";
  @Output() selectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();
  userDetails:any = {};
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  @Input() fromDateFromParent;
  @Input() datePickerLabel;
  @Input() isRequired;
    
    @ViewChild("datepicker") dp: NgbInputDatepicker;
    datePickerElement: HTMLElement; 
    
    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private datePipe: DatePipe,private storageService:StorageService ) {
      super();
      this.disableAutocomplete();
      this.onClear();

      this.userDetails = this.storageService.retrieve('userAccountDetails');
    }
  
    getMinSelectableDate(): NgbDate | null {
      const  {dateTime ,year,month ,date, time }:any   = this.getCurrentDateTimeBaseOnTimezone(this.userDetails);
      return NgbDate.from({ year: Number(year), month: Number(month), day: Number(date) });
      }
  
    // getMaxSelectableDate(): NgbDateStruct {
    // 	const currentDate = new Date();
    // 	const threeMonthsLater = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
      
    // 	return {
    // 	  year: threeMonthsLater.getFullYear(),
    // 	  month: threeMonthsLater.getMonth() + 1, // Months are zero-based, so add 1
    // 	  day: threeMonthsLater.getDate()
    // 	};
    //   }
  
    ngOnInit(): void {
      if(this.fromDateFromParent){
        this.assignToDatePicker()
      }
    }

    disableAutocomplete() {
      const formFields = document.getElementsByTagName('input');
      for (let i = 0; i < formFields.length; i++) {
        formFields[i].setAttribute('autocomplete', 'off');
      }
    }
  
    ngOnChanges(){
      if(this.fromDateFromParent){
        this.assignToDatePicker()
      }
    }

    checkDisabled(){
      return !(this.fromDate) && this.isInputValid(this.inputDate)== false;
    }
  
    onDateSelection(date: NgbDate) {
      if (!this.fromDate) {
        this.fromDate = date;
      }else{
        this.fromDate = date;
      }
      if(this.fromDate){
        let startDate = this.formatter.format(this.fromDate);
        const datefrom = this.datePipe.transform(startDate,'MM/dd/yyyy');
        this.inputDate = `${datefrom}`;
      }
      this.selectedDates.emit({startDate: this.fromDate});
    }
  
    onClear() {
      this.fromDate = null;
      this.inputDate = null;
      this.selectedDates.emit({
        startDate: this.fromDate
      });
      if(this.dp){
        this.dp.startDate = this.calendar.getToday();
      }
      
    }
    onApply(event) {
      this.selectedDates.emit({
        startDate: this.fromDate
      });
      this.dp.close();
      event?.stopPropagation()
    }
  
      // from parent (edit)start
    assignToDatePicker(){
      if(this.fromDateFromParent){
        const fromDateParse = this.formatter.parse(this.fromDateFromParent);
        const fromNgbDate = new NgbDate(fromDateParse.year, fromDateParse.month, fromDateParse.day);
        this.fromDate=fromNgbDate;
        const startDate = this.formatter.format(fromNgbDate);
        const datefrom = this.datePipe.transform(startDate, 'MM/dd/yyyy');
        this.inputDate = `${datefrom}`;
      } ;
    }
  
    onDateRangeInputChange(inputValue: string) {
        if(this.isInputValid(inputValue)){
          if (inputValue) {
            const startDate = this.parseDateString(inputValue);
            this.fromDate = startDate;
            const startDate1  = this.formatter.format(this.fromDate);
            const datefrom = this.datePipe.transform(startDate1, 'MM/dd/yyyy');
            this.inputDate = `${datefrom}`;
          }
        }
        else{
          this.fromDate = null;
          this.inputDate = inputValue;
        }
      this.checkDisabled();
    }
    
      private parseDateString(dateString: string): NgbDate | null {
      const parts = dateString.split('/');
      if (parts.length === 3) {
        const year = parseInt(parts[2], 10);
        const month = parseInt(parts[0], 10);
        const day = parseInt(parts[1], 10);
        return new NgbDate(year, month, day);
      }
      return null;
      }
  
      isInputValid(input: string): boolean {
      // Define the regex pattern for the format MM/dd/yyyy - MM/dd/yyyy
      const patternForSingle = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
      // Test if the input matches the pattern
      return patternForSingle.test(input);
       }
  
      openDatepicker(event?) {
      event?.stopPropagation();
      if(this.dp?.isOpen()) return;
      this.dp.open();
      if(this.fromDate){
        this.dp.navigateTo(this.fromDate)
      }else{
        const currentDate = new Date();
        this.dp.navigateTo({
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1, // Months are zero-based, so add 1
        day: currentDate.getDate()
        })
      }
      document?.body?.addEventListener('click', this.closeDatepickerOnClickOutside);
      } 

      closeDatepickerOnClickOutside = (event) => {
          const datepickerElement = document?.querySelector('.input_single_datepicker');
          const child = datepickerElement?.lastElementChild;
          const targetElement = event?.target;
          if (!child?.contains(targetElement)) {
              this.dp.close();
        }
    }

    close(e){
      document?.body?.removeEventListener('click', this.closeDatepickerOnClickOutside);
    }

}