// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyDJsdrpWrsc_QEIO2EH1owtknOp57KdJrU",
        authDomain: "cielo---stage.firebaseapp.com",
        projectId: "cielo---stage",
        storageBucket: "cielo---stage.appspot.com",
        messagingSenderId: "233606071380",
        appId: "1:233606071380:web:a0c1f99eb63df96415d9c1",
        measurementId: "G-1ERRXX2BTS",
        vapidKey: "BAYk3zP7T3Pm3zmOmbGKGm7mcqy2F-uSNpD_D31lJcnBZun6cYTn5lM5PY0lPpF-LyfrJc3bn4viHZNZPgE0934"
    },
};

export const baseUrl = "https://devcieloapi.12skiestech.com/";
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
