import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Ids, Notifications } from '../header/elements/notification/notification.model';
import { NotificationService } from '../header/elements/notification/notification.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent  {
  constructor(public notificationService : NotificationService){}
  @Input() notificationsData:Notifications[];
  @Output() dataChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('content') content!: ElementRef;
  openTab:number = 1;

  notificationIds = new Ids();
  @Output() clickOnNotificationTab = new EventEmitter();
  @Output() markAsRead    = new EventEmitter();
  @Output() deleteNotification = new EventEmitter();
  @Output() onScroll = new EventEmitter();
  
  ngOnChanges(){
    if(this.notificationsData.length > 0){
      this.notificationsData = this.notificationsData;
    }
  }

  get filteredNotificationsData(): any[] {
    return this.notificationsData.filter(notification => notification.isRead !== false);
  }


  checkAsmarkStatus():boolean{
    return this.notificationsData?.some(x => x.isRead !== true);
  }

}