import { CommunicationType } from './../../components/appointments-and-scheduling/request-list/pop-ups/create-edit-request/create-edit-request.model';
import { UpdateAppointmentRecordComponent } from "src/app/components/reports/pop-ups/update-appointment-record/update-appointment-record.component";

export enum CommonEnum {
}

export enum TimeDateFormatTypes {
    format1 = 'yyyy-MM-dd HH:mm:ss',
    format2 = 'yyyy-MM-ddTHH:mm:ss.SSSZ',
    format3 = 'yyyy-MM-ddTHH:mm:ss.SSS',
    timeFormat1 = 'hh:mm a',
    timeFormat2 = 'HH:mm', //TimeTo24HourFormat
}

export enum TabId {
    UserList = 1,
    Roles = 2,
    RolesAndPermissions = 3,
    InternalUserList = 4
}

export enum UserSettingstabId {
    UserDetails = 1,
    ScheduleAvailibility = 2
}

export enum Roles {
    Admin = "Cielo Admin",
    ClientAdmin = "Client Admin",
    SuperAdmin = "Super Admin",
    Interpreter = "Cielo Interpreter",
    TeamMember = "Cielo Team Member",
    Requester = "Client Requester",
    InterpreterEs = "CIELO Intérprete",

}

export enum Weekday {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}

export enum ActivityTypes {
    Active = 1,
    Inactive = 0
}

export enum ModalTitle {
    AddUserTitle = "USERMANAGEMENT.TABLIST.TABLABEL.USERLIST.ADDEDITUSERPOPUP.ADDUSER",
    EditUserTitle = "USERMANAGEMENT.TABLIST.TABLABEL.USERLIST.ADDEDITUSERPOPUP.EDITUSER",
    AddRoleTitle = "USERMANAGEMENT.TABLIST.TABLABEL.USERROLE.ADDEDITROLEPOPUP.ADDNEWROLE",
    EditRoleTitle = "USERMANAGEMENT.TABLIST.TABLABEL.USERROLE.ADDEDITROLEPOPUP.EDITROLE",
    ConfirmChangesTitle = "COMMON.COMMONMODALPOPUP.CONFIRMCHANGES",
    UnsavedChangesTitle = "COMMON.COMMONMODALPOPUP.UNSAVEDCHANGES",
    MarkAsActiveTitle = "COMMON.COMMONMODALPOPUP.MARKASACTIVE",
    MarkAsInactiveTitle = "COMMON.COMMONMODALPOPUP.MARKASINACTIVE",
    EditProfileTitle = "USERSETTINGS.TABLIST.TABLABEL.USERDETAILS.EDITPROFILE",
    FilterTitle = "COMMON.BUTTON.BUTTONLABEL.FILTER",
    ConfirmTitle = "COMMON.BUTTON.BUTTONLABEL.CONFIRM",
    TextAlertConfirmTitle = "RECEIVE.TEXT.ALERT.CONFIRM",
    ConfirmDelete = "CONFIRMDELETE.TITLE",
    RequestSubmitted = 'REQUESTESUBMITTED.TITLE',
    UnableToProceed = 'UNABLETOPROCEED.TITL',
    AppointmentUpdated = 'APPOINTMENTUPDATED.TITLE',
    UpdateAppointmentRecordTitle = "UPDATEAPPOINTMENTRECORD.TITLE",
    AppointmentForm = 'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.APPOINTMENTFORM',
    EditAppointmentForm = 'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.EDITAPPOINTMENTFORM',
    EditNotes = "EDITNOTES.TITLE",
    RequesterNotes = "REQUESTORNOTES.TITLE",
    InterpreterNotes = "INTERPRETERNOTES.TITLE",
    InternalNotes = "INTERNALNOTES.TITLE",
    MakeGroupInactive = "MAKEGROUPINACTIVEe.TITLE",
    MakeGroupActive = "MAKEGROUPACTIVE.TITLE",
    GroupCreated = "GROUPCREATED.TITLE",
    GroupDeleted = "GROUPDELETEED.TITLE",
    DeleteGroup = "DELETE.GROUP.TITLE",
    RemoveUserFromGrop = "REMOVEUSERFROMGROUP.TITLE",
    AddGroup = "USERMANAGEMENT.CLIENTUSERLIST.MANAGEORGANIZATIONUSERGROUP.ADDGROUP",
    EditGroup = "USERMANAGEMENT.CLIENTUSERLIST.MANAGEORGANIZATIONUSERGROUP.EDITGROUP",
    AddInternalUser = "USERMANAGEMENT.ADDINTERNALUSER.TITLE",
    EditInternalUser = "USERMANAGEMENT.EDITINTERNALUSER.TITLE",
    ManageOrganizationUsersGroupTitle = "USERMANAGEMENT.CLIENTUSERLIST.MANAGEORGANIZATIONUSERGROUP.TITLE",
    PasswordReset = "USERSETTINGS.TABLIST.TABLABEL.USERDETAILS.POPUP.HEADING.PASSWORDRESET",
    PasswordResetRequestSent = "USERSETTINGS.TABLIST.TABLABEL.USERDETAILS.POPUP.HEADING.PASSWORDRESETREQUESTSENT",
    QuickbooksLogoutConfirmation = "COMMON.COMMONMODALPOPUP.QUICKBOOKS_CONFIRMATION_HEADING"

}



export enum FormSettingTitle {
    communicationType = "Communication Type",
    serviceType = "Service Type",
    languagesAndVariants = "Languages & Variants",
    gender = "Gender",
    organization = "Organization",
    userGender = "User Gender",
    interpreterRates = "Interpreter Rates"
}
export enum GetLookupFormEnum {
    LanguageAndVariant = 1,
    ServiceType = 2,
    CommunicationType = 3
}

export enum EntryState {
    NONE = 0,
    POST = 1,
    PUT = 2,
    DELETE = 3,
}

export enum CalenderTabId {
    All = 1,
    InterpretationRequest = 2,
    LinguisticMatchCall = 3
}

export enum CheckBoxType {
    CLIENTNOSHOW = 1,
    ENDEDEARLY = 2,
    ENDEDLATE = 3,
    None
}

export enum AppointmentInternalStatus {
    New = 1,
    Pending = 2,
    Accepted = 3,
    AssigneePending = 4,
    Assigned = 5,
    Completed = 6,
    Canceled = 7,
    Declined = 8,
    NoShow = 9
}

export enum NoteType {
    InternalNote = 1,
    InterpreterNote = 2,
    RequestorNote = 3
}

// SignalRGroups
export enum SignalRGroups {
    DashboardPage = "DashboardPage",
    AppointmentPage = "AppointmentPage",
    CalendarPage = "CalendarPage",
}


export class HubConstant {
public static readonly AppointmentCreatedFunction               = "appointment.created";
public static readonly AppointmentUpdatedFunction               = "appointment.updated";
public static readonly AppointmentStatusUpdatedFunction         = "appointment_status.updated";
public static readonly AppointmentAssignEnabledFunction         = "appointment_assign.enabled";
public static readonly AppointmentOnDemandAutoDeclinedFunction  = "appointment_on_demand.auto_declined";
public static readonly StatisticsUpdatedFunction                = "dashboard.updated";
public static readonly AuditLogCreatedFunction                  = "audit_log.created";
public static readonly LogoutUserFunction                       = "logout";
public static readonly PermissionUpdateFunction                 = "permission.update";
}

export enum AppointmentChangeScope {
    AppointmentCreated = 1,
    AppointmentUpdated = 2,
    AppointmentStatusUpdated = 3,
    AutoAssignedPendingRequestReAssignEnabled  = 4
}

export const REMAIMBER_USERS = 'REMAIMBER_USERS'
export const USER_ORGANIZATIONS = "USER_ORGANIZATIONS";
export const MULTIPLEORG_ACCESSTOKEN = "MULTIPLEORG_ACCESSTOKEN";
export const SESSION_EXPIRE_TIME = "SESSION_EXPIRE_TIME";
export const INTERGRATED_APPS = "INTERGRATED_APPS";
export const CALL_LINK_VIDEO_LINK_REGEX = /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.(com|org|net|info|gov|edu|mil|int|ca|uk|us|au|in|io|me|tv|shop|xyz|online|blog|tech|cloud|art|design|fr|de|ru|cn|jp)(\/.*)?$/;
export const CALL_LINK_LINK_NUMBER_REGEX = /^((https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.(com|org|net|info|gov|edu|mil|int|ca|uk|us|au|in|io|me|tv|shop|xyz|online|blog|tech|cloud|art|design|fr|de|ru|cn|jp)(\/.*)?)|((tel:|\+)?[0-9]{1,4}[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{3,10}))$/;
export const CaliforniaId = 5;
export const EXTRACT_HOURS_MIN_SEC_REGEX = /(\d{2}):(\d{2}):(\d{2})/ ;

export enum RegexPattern {

    TextAreaMax750Char = '^([\\s\\S\\[\\]{}]{0,750})$'
}